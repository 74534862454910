<template>
  <!-- 案例中心 -->
  <div id="caseCenterPage" style="background:#f5f5f5;width:100%;padding-bottom:50px">
    <!-- 公司简介 -->
    <div class="shopContainer">
      <!-- 成交案例 -->
      <div class="case">
        <div class="serWrap flex">
          <div class="pagTite flex align-center">
            <span></span>
            <label for>成交案例</label>
          </div>
        </div>
        <div class="caseContent flex-row">
          <div
            class="caseLists"
            v-for="(item,index) in caseData"
            :key="index"
            @click="caseDetail(item.id)"
          >
            <img :src="item.coverUrl | imagePath" alt>
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="bottom w100 flex algin-center">
        <Pagination :total="total" @num='getNum'></Pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ShopHeader from '@/components/common/shopHeader'
import Pagination from '@/components/common/pagination'
export default {
  components: { Pagination, ShopHeader },
  data() {
    return {
      caseData: {},
      param: {
        storeId: this.$route.params.id,
        pageNum: 1,
        pageSize:16
      },
      total: 0
    }
  },
  mounted() {
    const { params } = this.$route
    const { id } = params
    this.param.storeId = id
    this.getCenterData()
    // console.log(this.$route.params.id,111,this.$route.params.id)
  },
  methods: {
    getCenterData(id) {
      
      this.$http.get('/store/case/list', { params: this.param }).then(res => {
        const { data ,totalSize} = res.data.data
        this.caseData = data
        // console.log(totalSize,this.caseData)
        this.total = totalSize
      })
    },
    getNum (val) {
      console.log(val,222)
      this.param.pageNum = val
      this.getCenterData()
    },
    // 跳转到案例详情页面
    caseDetail(id) {
      this.$router.push({
        path: '/caseDetail',
        query: {
          storeId:this.param.storeId,
          id: id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/change.scss';
.shopContainer {
  width: 1200px;
  min-height: 360px;
  margin: 0 auto;
  .busWrap {
    padding-top: 20px;
    overflow: hidden;
  }
  .busLeft {
    width: 295px;
    height: 290px;
    border: 1px solid #e5e5e5;
    .top {
      overflow: hidden;
      margin: 15px 20px 0 15px;
      padding-bottom: 18px;
      border-bottom: 1px solid #e5e5e5;
      .logWrap {
        width: 72px;
        height: 72px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logName {
        margin: 10px 0 0 15px;
        font-size: 15px;
        color: #666666;
        p {
          width: 170px;
          overflow: hidden;
          margin-bottom: 10px;
          font-size: 18px;
          color: #333333;
        }

        img {
          padding-right: 10px;
        }
      }
    }
    .down {
      padding: 15px 20px 18px 15px;
      font-size: 12px;
      color: #666666;
      p {
        margin-bottom: 15px;
        font-size: 15px;
        color: #333333;
      }

      .care {
        margin-left: 25px;
        img {
          margin-right: 6px;
        }
      }
    }
  }
  .busRight {
    margin-left: 15px;
    vertical-align: top;
    img {
      width: 886px;
      height: 290px;
    }
  }
  .serWrap {
    overflow: hidden;
    margin: 40px 0 35px;
    .pagTite {
      width: 400px;
      font-size: 18px;
      > span {
        display: inline-block;
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background: #0db168;
      }
    }
  }

  .caseContent {
    .caseLists {
      margin-bottom: 20px;
      margin-right: 20px;
      &:nth-child(4n) {
        margin-right: 0px;
      }
      img {
        width: 282px;
        height: 191px;
      }
      img:hover {
        background: #e5e5e5;
        opacity: 0.5;
        cursor: pointer;
      }
      span {
        display: block;
        padding: 10px 0;
        text-align: center;
        &:hover {
          color: #0db168;
          cursor: pointer;
        }
      }
    }
  }
}

.bottom {
  text-align: center;
  padding: 30px 0;
}
</style>