<template>
  <div class="product_wrap h100">
    <div style="background:#f5f5f5;" class="w100 h100">
      <section>
        <div class="left_sec">
          <div class="title">热卖推荐</div>
          <div class="main_item" v-for="(list,index) in hotList" :key="index" @click="detail(list.productId)">
            <div class="item">
              <img :src="list.productPic | imagePath" alt>
              <div class="name">{{list.name}}</div>
              <div class="price">￥{{list.price | price}}</div>
            </div>
          </div>
        </div>
        <div class="right_sec flex-1">
          <div class="top_fliter flex align-center">
            <div class="fliter_conditions" @click="getSortPrice('price')">
              <label class="sortName">价格</label>
              <img
                src="../../assets/image/shangshen.png"
                :class="[price === 'asc' ? '' : 'r180']"
              >
            </div>
            <div class="fliter_conditions down" @click="getSortSale('sale')">
              <label class="sortName">销量</label>
              <img
                src="../../assets/image/shangshen.png"
                :class="[sale === 'asc' ? '' : 'r180']"
              >
            </div>
          </div>
          <div class="productList flex flex-wrap">
            <div
              class="product"
              v-for="(item,index) in searchList"
              :key="index"
              @click="detail(item.id)"
            >
              <img :src="item.pcUrl | imagePath" alt>
              <div class="productName">
                <span>{{item.productName}}</span>
              </div>
              <div class="productPrice" v-show="item.price!=0">￥{{item.price | price}}</div>
            </div>
          </div>
          <div class="pagination w100 flex align-center">
            <Pagination :total="total" v-on:num="getNum"></Pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/pagination'
import {mapGetters} from 'vuex'
export default {
  name: 'index',
  components: { Pagination },
  props: {
    searchList: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      price: 'asc',
      sale: 'asc',
      hotList: [], // 热卖推荐
      storeId:this.$route.params.id
    }
  },
  computed: {
    // ...mapGetters(['store']) //获取storeId,火狐不支持
  },
  mounted() {
    this.gethotLists()
  },
  methods: {


    getNum(val) {
      this.$emit('change', {pageNum: val})
    },

    // 热卖推荐信息
    gethotLists() {
      // this.$http.get('/storeProductInfo/sort/sale', { params: { storeId: this.store.id } }).then(res => {
      //   const { data } = res.data
      //   this.hotList = data
      //   console.log(data)
      // })
       this.$http.get('/storeProductInfo/sort/sale', { params: { storeId: this.storeId} }).then(res => {
        const { data } = res.data
        this.hotList = data
        // console.log(data)
      })
    },

    getSortPrice(val) {
      this.price = this.price === 'asc' ? "desc" : 'asc'
      this.$emit('change', {price: this.price})
    },

    getSortSale(val) {
      this.sale = this.sale === 'asc' ? "desc" : 'asc'
       this.$emit('change', {sale: this.sale})
    },

    // 商家首页跳转到产品服务商列表
    detail(id) {
      this.$router.push({
        path: '/productDetail',
        query: {
          pid: id,
          sid: this.storeId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
.r180 {
  transform: rotate(180deg);
  margin: 0;
}
.sortName {
  color: #0db168;
  cursor: pointer;
}

section {
  padding-top: 20px;
}
.left_sec {
  width: 224px;
  overflow-x: auto;
  margin-right: 20px;
  float: left;
  background: #fff;
  .title {
    line-height: 40px;
    height: 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    background: #0db168;
  }
  .main_item {
    width: 100%;
    cursor: pointer;
    margin-bottom: 20px;
    
    .item {
      width: 95%;
      background: #fff;
      padding-bottom: 10px;
      margin: 20px auto 10px;
      box-sizing: border-box;
      img {
        width: 184px;
        height: 184px;
        margin-left: 15px;
      }
      div {
        text-align: left;
        padding-left: 15px;
        &.name {
          font-size: 16px;
          color: #333333;
          margin: 10px 0 5px 0;
          padding-right: 4px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        &.price {
          font-size: 18px;
          color: #ff0036;
        }
      }
    }
  }
}

.right_sec {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  .top_fliter {
    height: 40px;
    background: #fff;
    text-align: left;
    margin-bottom: 20px;
    .fliter_conditions {
      width: 100px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      label {
        padding-right: 10px;
      }
      span {
        display: inline-block;
        height: 100%;
        width: 100%;
        &.up {
          background: url(../../assets/image/shangshen.png) center no-repeat;
          background-size: 14px;
          background-position-x: 70px;
          background-position-y: 10px;
          color: #0db168;
        }
        &.down {
          background: url(../../assets/image/xiajiang.png) center no-repeat;
          background-size: 14px;
          background-position-x: 70px;
          background-position-y: 14px;
        }
      }
    }
  }
  .productList {
    width: 956px;
    // overflow: hidden;
    // display: flex;
    // justify-content: flex-start;
    
    .product {
      // width: 25%;
      width: 224px;
      height: 300px;
      margin: 0 20px 20px 0;
      cursor: pointer;
      background: #fff;
      > img {
        width: 100%;
        height: 224px;
      }
      img:hover {
        opacity: 0.6;
        background-color: rgba(0, 0, 0, 0.5);
      }
      div {
        font-size: 16px;
        margin-top: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .productPrice {
        color: #ff0036;
        font-size: 18px;
        margin-top: 10px;
        padding-left: 15px;
      }
      &:hover {
        color: #0db168;
        cursor: pointer;
      }
    }
    div:nth-of-type(4n){
      margin-right: 0;
    }
   
   .productName{
      padding:0 15px;
      span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.left_sec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.left_sec {
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none;
  overflow: auto;
}
.left_sec .-o-scrollbar {
  -moz-appearance: none !important;
  background: rgba(0, 255, 0, 0) !important;
}
</style>