<template>
  <!-- 企业信息 -->
  <div id="companyInfoPage">
    <div class="shopContainer">
      <!-- 中间内容 -->
      <div class="middle row">
        
        <div class="busLeft">
          <div class="right">
            <div class="topPart">
              <div class="qyIntro">企业介绍</div>
              <div class="li">
                企业名称：
                <span>{{companyInfo.companyName}}</span>
              </div>
              <div class="li">
                法定代表人：
                <span>{{companyInfo.compLegalPerson}}</span>
              </div>
              <!-- <div class="li">
                成立时间：
                <span>{{companyInfo.companyCreateTime}}</span>
              </div> -->
              <div class="li last-li">
                企业地址：
                <span>{{companyInfo.areaDesc}}</span>
                <!-- <span>江苏省南京市鼓楼区汉中路180号星汉大厦7楼C座</span> -->
              </div>
              <div class="introduce">
                <div class="name">企业简介</div>
                <p class="intros">
                  {{companyInfo.companyShortName}}
                </p>
              </div>
            </div>


            <!-- <div class="bottomPart">
              <div class="qyIntro">服务评价</div>
              <div class="answerNav">
                <span>好评(125)</span>
                <span>中评(12)</span>
                <span>差评(0)</span>
              </div>
              <div class="answerBox flex flex-between">
                <div class="busLeftInfo flex-y align-center">
                  <img src="../../../assets/shopImg/" alt="">
                  <div class="avatarl">头像</div>
                  <p class="f14">13098766789</p>
                </div>
                <div class="answerInfo flex-y">
                  <p class="p10 title">服务态度棒棒哒！出图快，设计水平高，以后多多推荐！</p>
                  <div class="flex align-start">
                    <div class="black"></div>
                    <div class="flex-y">
                      <p class="f14 p10">产品名称</p>
                      <p class="f12 gray">2018-05-15</p>
                    </div>
                  </div>
                </div>
                <div class="price flex align-center">
                  <span>1000.00</span>元
                </div>
              </div>
              <div class="answerBox flex flex-between">
                <div class="leftInfo flex-y align-center">
                  <img src="../../../assets/shopImg/" alt="">
                  <div class="avatarl">头像</div>
                  <p class="f14">13098766789</p>
                </div>
                <div class="answerInfo flex-y">
                  <p class="p10 title">服务态度棒棒哒！出图快，设计水平高，以后多多推荐！</p>
                  <div class="flex align-start">
                    <div class="black"></div>
                    <div class="flex-y">
                      <p class="f14 p10">产品名称</p>
                      <p class="f12 gray">2018-05-15</p>
                    </div>
                  </div>
                </div>
                <div class="price flex align-center">
                  <span>1000.00</span>元
                </div>
              </div>
              <div class="answerBox flex flex-between">
                <div class="leftInfo flex-y align-center">
                  <img src="../../../assets/shopImg/" alt="">
                  <div class="avatarl">头像</div>
                  <p class="f14">13098766789</p>
                </div>
                <div class="answerInfo flex-y">
                  <p class="p10 title">服务态度棒棒哒！出图快，设计水平高，以后多多推荐！</p>
                  <div class="flex align-start">
                    <div class="black"></div>
                    <div class="flex-y">
                      <p class="f14 p10">产品名称</p>
                      <p class="f12 gray">2018-05-15</p>
                    </div>
                  </div>
                </div>
                <div class="price flex align-center">
                  <span>1000.00</span>元
                </div>
              </div>
            </div> -->


          </div>
        </div>


        <div class="busRight">
          <div class="serviceBox">
            <div class="name">擅长服务</div>
            <div class="flex-row">
              <div
                class="tagServer"
                v-for="(item,index) in companyInfo.serviceList"
                :key="index"
              >{{item}}</div>
            </div>
          </div>
          <div class="manageBox serviceBox">
            <span class="f18">经营资质</span>
            <div class="bg" @click="dialogTableVisible = true">
              <img :src="companyInfo.qiyePassport | imagePath" alt>
            </div>
            <p>企业营业执照</p>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>

    <!-- 点击营业执照图片放大 -->
    <el-dialog title="" :visible.sync="dialogTableVisible">
        <div class="license">
          <img :src="companyInfo.qiyePassport | imagePath" alt="营业执照">
        </div>
    </el-dialog>

  </div>
</template>
<script>
import ShopHeader from '@/components/common/shopHeader'
import Pagination from '@/components/common/pagination'
import { constants } from 'fs'

export default {
  components: { ShopHeader, Pagination },
  data() {
    return {

         gridData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }],
        dialogTableVisible: false,
        dialogFormVisible: false,

      companyInfo: {
        qiyePassport: '', // 营业执照
        companyName: '', // 企业名称
        compLegalPerson: '', // 法定代表人
        companyCreateTime: '', // 成立时间
        areaDesc: '', // 企业地址
        companyShortName: '' // 企业简介
      }
    }
  },
  mounted() {
    const { params } = this.$route
    const { id } = params
    this.getcompanyInfo(id)
  },
  methods: {
    clickBg(){

    },
    getcompanyInfo(id) {
      this.$http.get('/company', { params: { storeId: id } }).then(res => {
        const { data } = res.data
        this.companyInfo = data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/change.scss';
.shopContainer{
  max-width: 1200px;
  padding-bottom: 50px;
}
.busLeft{
  background: #fff;
  width: 840px;
  height: 100%;
  min-height: 620px;
}
#companyInfoPage {
  margin-top: 30px;
}
.middle {
  height: auto;
  // background: #fff;
  // margin-bottom: 30PX;
}
.busRight {
  width: 340px;
  // background: deepskyblue;
}
.serviceBox,
.manageBox {
  height: 300px;
  background: #fff;
}

.serviceBox {
  padding: 20px 30px;
  margin-bottom: 20px;
  .name {
    color: #333;
    font-size: 18px;
    padding-bottom: 20px;
  }
  .tagServer {
    margin-right: 30px;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
    text-align: center;
    &:hover {
      border-color: #0db168;
      color: #0db168;
      cursor: pointer;
    }
  }
}

.manageBox {
  padding: 15px;
  .bg {
    width: 100%;
    height: 180px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    text-align: center;
    padding: 14px;
    color: #333;
  }

  span {
    display: block;
    padding-bottom: 19px;
    color: #333;
  }
}

.right {
  // width: px;
  .topPart,
  .bottomPart {
    margin-top: 20px;
    padding:0 30px;
  }
  .qyIntro {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 900;
  }
  .li {
    // padding: 5px 0 ;
    line-height: 32px;
    font-size: 14px;
    color: #666;
    // &:last-child {
    //     padding-bottom: 18px;
    // }
  }

  .last-li {
    padding-bottom: 20px;
  }

  .introduce {
    border-top: 1px dashed #e5e5e5;
    .name {
      padding: 25px 0 20px;
      font-size: 16px;
      margin-top: 10px;
      color: #333;
    }

    .intros {
      line-height: 25px;
      font-size: 14px;
      color:#666;
    }
  }

  .answerNav {
    span {
      font-size: 13px;
      display: inline-block;
      letter-spacing: 2px;
      padding-right: 25px;
      &:first-child {
        color: #0db168;
      }
      &:hover {
        cursor: pointer;
        color: #0db168;
      }
    }
  }

  .answerBox {
    margin-top: 20px;
    display: flex;
    flex-direction: flex-start;
  }
  .avatarl {
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: #666;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .mobile {
    color: #666;
    font-size: 16px;
  }
  .leftInfo {
    width: 120px;
  }
  .answerInfo {
    margin-left: 15px;
    .p10 {
      padding: 5px 0;
    }
    .title {
      margin-bottom: 14px;
    }
    .black {
      width: 40px;
      height: 40px;
      background: #666;
      margin-right: 16px;
    }
  }
  .price {
    margin-right: 60px;
    span {
      color: #0db168;
    }
  }
}

.license{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
  img{
    display: block;
    width: 70%;
    height: 70%;
    cursor: pointer;
  }
}
</style>