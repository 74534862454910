<template>
  <div id="shopPage">
    <div style="background:#f5f5f5;width:100%;">
      <ShopHeader @change="changeHandler($event)"></ShopHeader>
      <div class="shopContainer" v-show="selectIndex === 1">
        <div class="serWrap flex flex-between">
          <div class="pagTite flex align-center">
            <span></span>
            <label>全部产品</label>
          </div>
          <div class="searWrap">
            <input type="text" name placeholder="输入产品名称" v-model="param.productName" @keydown="addByEnterKey">
            <span @click="search()">
              搜索
              <!-- <img src="../../assets/shopImg/sousuo.png"> -->
            </span>
          </div>
        </div>
        <LevelsMenu @change="queryProduct"></LevelsMenu>
        <productList :searchList="searchList" :total='total' @change="sort"></productList>
      </div>
      <CaseCenter v-show="selectIndex === 2"></CaseCenter>
      <Company v-show="selectIndex === 3"></Company>
    </div>
  </div>
</template>
<script>
import ShopHeader from '@/components/common/shopHeader'
import LevelsMenu from '@/components/common/levelsMenu'
import ProductList from '@/components/common/productList'
import Pagination from '@/components/common/pagination' 
import CaseCenter from './caseCenter' 
import Company from './companyInfo' //企业信息主体
export default {
  components: { ShopHeader, ProductList, Pagination, LevelsMenu, CaseCenter, Company },
  data() {
    return {
      selectIndex: 1,
      param: {
        cateId: '',
        pageNum: 1,
        pageSize: 24,
        price: 'asc',
        productName: '',
        sale: 'asc',
        storeId: ""
      },
      searchList: [], // 搜索后的列表数据
      total: 0
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.param.storeId = id; //店铺id
    this.searchData()
  },
  methods: {
    addByEnterKey(e) {
	        //Enter键的代码就是13
            if (e.keyCode == 13) {
              this.search();
            }
        },
    search () { //店铺内的搜索
       this.param.pageNum = 1;
       this.param.pageSize = 50;
      this.$http.post('/product/storeProductList', this.param ).then(res => {
      this.searchList = res.data.data.data;
      this.param.productName="";
      })
    },
    searchData() { //案例主页点击三级分类出现产品
      this.$http.post('/product/storeProductList', this.param).then(res => {
        console.log(res)
        const { data,totalSize } = res.data.data
        this.searchList = data
        this.total = totalSize
      })
    },
    changeHandler(index) {
      this.selectIndex = index
    },
    queryProduct(catid) {
      this.param.pageNum = 1
      this.param.cateId = catid
      this.searchData()
    },
    sort (e) {
      this.param.pageNum = 1
      Object.assign(this.param, e)
      this.searchData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
#shopPage {
  margin-top: 0 !important;
  // padding-top: 170px;
}
.shopContainer {
  max-width: 1200px;
  width: 100%;
  min-height: 360px;
  margin: 0 auto;
  padding-top: 26px;
  .serWrap {
    overflow: hidden;
    padding:20px 20px 10px 20px;
    background: #fff;
    .pagTite {
      width: 400px;
      font-size: 18px;
      > span {
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 10px;
        background: #d82229;
      }
    }
    .searWrap {
      width: 240px;
      height: 40px;
      margin-bottom: 6px;
      margin-right: 17px;
      input {
        width: 164px;
        height: 100%;
        padding-left: 10px;
        border: 1px solid #333;
        outline: none;
        color: #999999;
        font-size: 14px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      span {
        display: inline-block;
        background: #333;
        color: #fff ;
        width: 76px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        vertical-align: top;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        cursor: pointer;
        // img {
        //   padding-top: 7px;
        // }
      }
    }
  }
}
</style>


